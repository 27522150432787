
import { computed, defineComponent, onMounted } from "vue";
import { LoadEntity } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import { onUpdated } from "@vue/runtime-core";
import RepricingForm from "@/views/invoice/RepricingForm.vue";
import router from "@/router";

export default defineComponent({
  name: "InvoiceRepricing",
  components: {RepricingForm},
  setup() {
    const invoice = computed(() => store.state.InvoiceModule.invoice)
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Reprice Invoice', [
        {link: true, router: '/invoices/' + invoice?.value?.id, text: 'View Invoice'},
        {link: false, router: '', text: 'Repricing Invoice'}
      ])
    })

    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Reprice  Invoice', [
        {link: true, router: '/invoices/' + invoice?.value?.id, text: 'View Invoice'},
        {link: false, router: '', text: 'Reprice Invoice'}
      ])
    })
    const onSavedInvoice = (i: any) => {
      router.push({
        name: 'invoiceOverview',
        params: {id: i.id}
      })
    }
    return {
      ...LoadEntity(Actions.LOAD_INVOICE),
      invoice,
      onSavedInvoice,
    }
  },

})
