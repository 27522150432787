
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {REPRICE_STATUS} from "@/core/config/Constant";
import BaseCheckBox from "@/components/base/checkbox/BaseCheckBox.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import {Field} from "vee-validate";
import {PPOSelect} from "@/core/composite/composite";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import InvoiceService from "@/core/services/InvoiceService";
import CompanyService from "@/core/services/CompanyService";
import Froala from "@/components/base/editor/Froala.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";

export default defineComponent({
  name: "RepricingForm",
  components: {FormErrorMessage, Froala, BaseSelect, CurrencyFormat, BaseCheckBox, BaseRadio, BaseForm, Field},
  props: {
    invoice: {type: Object, required: true}
  },
  emits: ['repriced'],
  setup(props, {emit}) {
    const savedAmount = ref(0);
    const trFee = ref(0);
    const model = ref({
      lines: [{repriced: 0}], repriceStatus: '', repriceLine: false, repriced: 0, discountPercent: 0, ppoId: '',
      clientFeePercent: 17,
      clientFee: 0,
      ppoFeePercent: 10,
      ppoFee: 0,
      repricedComment: '',
      ppoRefId :'',
    })
    const submitting = ref(false);
    const buildModel = (invoice) => {
      model.value.repriceStatus = invoice.repriceStatus ? invoice.repriceStatus.code : 'TO_REPRICE'
      model.value.repriceLine = invoice.repriceLine;
      model.value.repriced = invoice.repriced;
      model.value.discountPercent = invoice.discountPercent;
      model.value.ppoId = invoice.ppo ? invoice.ppo.id : '';
      model.value.clientFeePercent = invoice.clientFeePercent;
      model.value.clientFee = invoice.clientFee;
      model.value.ppoFeePercent = invoice.ppoFeePercent;
      model.value.ppoFee = invoice.ppoFee;
      model.value.repricedComment = invoice.repricedComment;
      model.value.ppoRefId = invoice.ppoRefId
      savedAmount.value = Number((invoice.billed - invoice.repriced).toFixed(2));

    }
    const buildLines = (lines: any) => {
      model.value.lines = lines;
    }
    const calculateCommission = () => {
      const clientFee = (savedAmount.value / 100) * model.value.clientFeePercent;
      model.value.clientFee = Number(clientFee.toFixed(2));
      const ppoFee = (savedAmount.value / 100) * model.value.ppoFeePercent;
      model.value.ppoFee = Number(ppoFee.toFixed(2));
      trFee.value = model.value.clientFee - model.value.ppoFee
    }
    const calculateDiscount = () => {
      model.value.repriced = props.invoice.billed - savedAmount.value;
      const percent = (savedAmount.value / props.invoice.billed) * 100
      model.value.discountPercent = Number(percent.toFixed(2))
      calculateCommission();
    }

    const ppoSelect = async (id) => {
      if (id) {
        await CompanyService.getFee(id).then(res => {
          model.value.ppoFeePercent = res.ppoFee;
          model.value.clientFeePercent = res.clientFee;
        })
      }
      calculateCommission();
    };

    const repriceByLine = () => {
      const repricedAmount = model?.value?.lines.map(item => Number(item.repriced)).reduce((prev, curr) => prev + curr, 0);
      model.value.repriced = Number(repricedAmount.toFixed(2));
      savedAmount.value = Number((props.invoice.billed - repricedAmount).toFixed(2));
      calculateDiscount();
      calculateCommission();
    }

    buildLines(props.invoice.lines)
    buildModel(props.invoice);
    watch(() => props.invoice.lines, (cb) => {
      buildLines(cb);
    });
    const submit = () => {
      submitting.value = true
      InvoiceService.reprice(props.invoice.id, model.value).then(res => {
        emit('repriced', {id: res.id});
      }).finally(() => {
        submitting.value = false;
      })
    }
    const validate = {};

    return {
      model,
      submitting,
      validate,
      REPRICE_STATUS,
      savedAmount,
      ...PPOSelect(),
      repriceByLine,
      submit,
      calculateDiscount,
      buildModel,
      calculateCommission,
      trFee,
      ppoSelect
    }
  },
  methods: {
    onCancel() {
      this.$router.go(-1)
    }
  }

})
