import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = {
  key: 0,
  class: "card-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RepricingForm = _resolveComponent("RepricingForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, "Reprice invoice " + _toDisplayString(_ctx.invoice.number), 1)
    ]),
    (_ctx.invoice.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_RepricingForm, {
            invoice: _ctx.invoice,
            onRepriced: _ctx.onSavedInvoice
          }, null, 8, ["invoice", "onRepriced"])
        ]))
      : _createCommentVNode("", true)
  ]))
}